import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import qs from 'qs';
import {
  Alert,
  Badge,
  Button,
  Col,
  Icon,
  Modal,
  Row,
  Spin,
  Tooltip,
} from 'antd';
import { H2 } from '../../../../Common/H2';
import { SlackIntegrationTeam } from './SlackIntegrationTeam';
import { eventNames, logEvent } from '../../../../../analytics';
import {
  integrateSlackFunction,
  unintegrateSlackFunction,
} from '../../../../../functions';
import { Store } from '../../../../../store';
import { History, Location } from 'history';
import { hasMissingV2Scopes, slackAuthorizeUrl } from '../authorize';

const { confirm } = Modal;

type Props = {
  store: Store;
  location: Location;
  history: History;
};

type State = {
  isIntegrating: boolean;
  isUnintegrating: boolean;
};

class _SlackIntegration extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isIntegrating: false,
      isUnintegrating: false,
    };
  }

  componentDidMount = async () => {
    if (
      this.props.store.slackIntegration &&
      !hasMissingV2Scopes(this.props.store.slackIntegration?.scope)
    ) {
      // 既に連携中の場合
      return;
    }

    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const { code } = query; // eslint-disable-line
    if (this.props.location.pathname === '/settings/integrations/slack' && code)
      (async () => this.integrateSlack(code as string))();
  };

  integrateSlack = async (code: string) => {
    this.setState({ isIntegrating: true });
    await integrateSlackFunction({
      companyId: this.props.store.signInCompany,
      code,
    });
    logEvent(eventNames.integrate_slack);
    this.props.history.push('./integrations');
    this.setState({ isIntegrating: false });
  };

  unintegrateSlack = async () => {
    confirm({
      title: 'Slackとの連携を解除します。よろしいですか？',
      content:
        '会社に紐付けられたすべてのSlackチャンネルへの通知が無効になります。\n ※解除後でも、Slackワークスペースの権限があれば再度連携が可能です。',
      okText: '解除',
      cancelText: 'キャンセル',
      onOk: async () => {
        this.setState({ isUnintegrating: true });
        await unintegrateSlackFunction({
          companyId: this.props.store.signInCompany,
        });
        logEvent(eventNames.unintegrate_slack);
        this.setState({ isUnintegrating: false });
      },
      onCancel() {
        //
      },
      okType: 'danger',
      maskClosable: true,
    });
  };

  render() {
    const {
      slackIntegrationsLoading,
      slackIntegration,
      isV2Plan,
      isSlackNotificationV2Supported,
    } = this.props.store;
    const { isAdmin } = this.props.store.me;
    const { isIntegrating, isUnintegrating } = this.state;
    const integratable =
      (!isV2Plan || isSlackNotificationV2Supported) && isAdmin;

    return (
      <div>
        <Content>
          {slackIntegrationsLoading ? (
            <Spin />
          ) : slackIntegration ? (
            <div>
              <div style={{ margin: 10 }}>
                <Badge status="success" />
                <b>{slackIntegration.team.name}</b>に連携されています
              </div>
              <Tooltip title="権限がありません" visible={!isAdmin}>
                {isV2Plan && hasMissingV2Scopes(slackIntegration?.scope) && (
                  <Alert
                    message={
                      <Row type="flex" justify="space-between" align="middle">
                        <Col>
                          通知に必要な権限の取得のため再連携の必要があります
                        </Col>
                        <Col>
                          <Tooltip
                            title={
                              !isSlackNotificationV2Supported &&
                              'Slack通知は現在のプランではサポートされていません'
                            }
                          >
                            <Button
                              type="primary"
                              href={slackAuthorizeUrl(isV2Plan)}
                              disabled={
                                !isAdmin || !isSlackNotificationV2Supported
                              }
                            >
                              再連携
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                    }
                  />
                )}
                <Button
                  type="danger"
                  onClick={this.unintegrateSlack}
                  loading={isUnintegrating}
                  disabled={isUnintegrating || !isAdmin}
                  style={{ marginTop: 10 }}
                >
                  連携を解除
                </Button>
              </Tooltip>
            </div>
          ) : (
            <div>
              <p>
                <Badge status="default" />
                連携されていません
              </p>
              <Spin
                indicator={
                  <Icon type="loading" style={{ fontSize: 24 }} spin />
                }
                spinning={isIntegrating}
                style={{ width: 140 }}
              >
                {integratable ? (
                  <a href={slackAuthorizeUrl(isV2Plan)}>
                    <img
                      alt="Add to Slack"
                      height="40"
                      width="139"
                      src="https://platform.slack-edge.com/img/add_to_slack.png"
                      srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                    />
                  </a>
                ) : (
                  <>
                    {!isAdmin && (
                      <Alert message={'連携を実行する権限がありません'} />
                    )}
                    {!isV2Plan && !isSlackNotificationV2Supported && (
                      <Alert
                        message={
                          'Slack通知は現在のプランではサポートされていません'
                        }
                      />
                    )}
                  </>
                )}
              </Spin>
            </div>
          )}
        </Content>

        {slackIntegration && (
          <Content>
            <H2>通知先チャンネル</H2>
            {this.props.store.joinedTeams.map((t) => (
              <SlackIntegrationTeam
                team={t}
                inboxes={this.props.store.getTeamInboxes(t.id)}
                key={t.id}
                isAdmin={this.props.store.me.isAdmin}
              />
            ))}
          </Content>
        )}
      </div>
    );
  }
}

const Content = styled.div`
  margin-bottom: 30px;
`;

export const SlackIntegration = compose<
  Props,
  Omit<Props, 'store' | 'history' | 'location'>
>(
  withRouter,
  inject('store'),
  observer
)(_SlackIntegration);
